import { createSlice } from '@reduxjs/toolkit'

export const registerGatewayRequestsSlice = createSlice({
    name: 'registerGateway',
    initialState: {
        loaded: false,
        loading: false,
        items: []
    },

    reducers: {
        loadRegisterGatewayRequestList: (state,{payload}) => {
            state.loaded = false
            state.loading = true
        },

        loadedRegisterGatewayRequestList: (state, {payload})=>{
            state.loaded = true
            state.loading = false
            state.items = []

            Object.entries(payload).forEach(([id, value]) => {
                state.items.push(value)
            })
        },

        addRegisterGatewayRequest: (state)=>{
        },

        deleteRegisterGatewayRequest: (state)=>{
        },

        resetRegisterGatewayRequest: (state)=>{
        },
    }
})

export const {
    loadRegisterGatewayRequestList,
    loadedRegisterGatewayRequestList,
    addRegisterGatewayRequest,
    deleteRegisterGatewayRequest,
    resetRegisterGatewayRequest,

} = registerGatewayRequestsSlice.actions

export default registerGatewayRequestsSlice.reducer
