import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from '@mui/material/MenuItem';
import {IconButton} from "@mui/material";
import { Link as RouterLink, Route, MemoryRouter } from 'react-router-dom';
import {useSelector} from "react-redux";

export default function AppMenu() {
    const userLogin = useSelector(state => state.userLogin)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const items = [
            {needLogin: false, caption: "Home", to:"/"},
            // {needLogin: true, caption: "test", to:"/other"},
            {needLogin: true, caption: "Gateways", to:"/gateways"},
        ]

    if (userLogin.user.isAdmin){
        items.push({needLogin: true, caption: "Calculator", to:"/calculator"})
        items.push({needLogin: true, caption: "Register Gateway", to:"/register-gateway"})
    }

    return (
        <div>
            <IconButton
                size="medium"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleClick}
            >
                <MenuIcon />
            </IconButton>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {items.map((item, idx) => {
                    if ((item.needLogin && userLogin.loggedIn) || (!item.needLogin)) {
                        return (
                            <MenuItem onClick={handleClose} key={idx} component={RouterLink}
                                      to={item.to}>{item.caption}</MenuItem>
                        )
                    }
                })
                }

            </Menu>
        </div>
    );
}