import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    Button,
    Card,
    CardContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip
} from "@mui/material";
import {dispatchToStore} from "../store";
import {
    addRegisterGatewayRequest,
    deleteRegisterGatewayRequest,
    loadRegisterGatewayRequestList, resetRegisterGatewayRequest
} from "../store/slices/registerGatewayRequests";
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

export default function RegisterGatewayPage() {
    const registeredGatewayRequestsList = useSelector(state => state.registerGatewayRequests)

    useEffect(() => {
        // on page load
        console.log("pageLoad")
        dispatchToStore(loadRegisterGatewayRequestList())
        return ()=>{
            // on page leave
            console.log("pageUnload ")
        }

    }, []);

    function reset_request(idx){
        console.log("resetting", idx)
        dispatchToStore(resetRegisterGatewayRequest({idx: idx}))
    }

    function delete_request(idx){
        console.log("delete", idx)
        dispatchToStore(deleteRegisterGatewayRequest({idx: idx}))
    }

    function register_new(){
        console.log("register_new")
        dispatchToStore(addRegisterGatewayRequest())
    }


    return (
        <Card>
            <CardContent>
                <h1>Register Gateway Request List</h1>

                <Tooltip title={"Create a new Register Gateway Request"}>
                    <Button color="primary" onClick={(e)=>{register_new()}} variant="contained">
                        <AppRegistrationIcon /> Register new gateway
                    </Button>
                </Tooltip>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Serial Number</TableCell>
                                <TableCell align="center">Used</TableCell>
                                <TableCell align="center">Created at</TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { Object.entries(registeredGatewayRequestsList.items).map(([id, item]) => { return (
                                <TableRow key={id} >
                                    <TableCell>{item.sn}</TableCell>
                                    <TableCell align="center">{item.used}</TableCell>
                                    <TableCell align="center">{item.created_at}</TableCell>
                                    <TableCell align="center">
                                        {item.used !== 0 ? <Tooltip title={"Reset Register Request and allow to register again"}>
                                            <Button color="primary" onClick={(e)=>{reset_request(item.id)}} >
                                                    <RestoreIcon />
                                            </Button>
                                        </Tooltip>:<></>}

                                        <Tooltip title={"Delete Register Request"}>
                                            <Button color="primary" onClick={(e)=>{delete_request(item.id)}}>
                                                <DeleteForeverIcon />
                                            </Button>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                                )})
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )

}
