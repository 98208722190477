import { put } from 'redux-saga/effects';
import {loadedGatewayList, updateGatewayListModeDone} from '../slices/gatewayList'
import {recaderoRequest} from "./recaderoApi";
import {updateNodeListPropDone} from "../slices/nodeList";
import {loadedRegisterGatewayRequestList} from "../slices/registerGatewayRequests";


export function * registerGatewayRequestsListLoad(action){

    const requestData = {}

    // execute api request
    const response = yield recaderoRequest("gateway-register", "list", requestData)

    if (response){
        yield put(loadedRegisterGatewayRequestList(response))
    } else {
        yield put(loadedRegisterGatewayRequestList({}))
    }

}

export function * registerGatewayRequestsAdd(action){

    const requestData = {}

    // execute api request
    const response = yield recaderoRequest("gateway-register", "add", requestData)

    if (response){
        yield put(loadedRegisterGatewayRequestList(response))
    } else {
        yield put(loadedRegisterGatewayRequestList({}))
    }

}

export function * registerGatewayRequestsDelete(action){

    const requestData = {
        idx: action.payload.idx,
    }

    // execute api request
    const response = yield recaderoRequest("gateway-register", "delete", requestData)

    if (response){
        yield put(loadedRegisterGatewayRequestList(response))
    } else {
        yield put(loadedRegisterGatewayRequestList({}))
    }

}

export function * registerGatewayRequestsReset(action){

    const requestData = {
        idx: action.payload.idx,
    }

    // execute api request
    const response = yield recaderoRequest("gateway-register", "reset", requestData)

    if (response){
        yield put(loadedRegisterGatewayRequestList(response))
    } else {
        yield put(loadedRegisterGatewayRequestList({}))
    }

}